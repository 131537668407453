import 'twin.macro'

/**
 * Center children
 */
function FullPageCentered({
  as: Component = 'div',
  ...props
}: {
  children: React.ReactNode
  as?: keyof JSX.IntrinsicElements | React.ComponentType
}) {
  return (
    <Component
      tw="flex flex-col justify-center items-center min-h-screen"
      {...props}
    />
  )
}

export {FullPageCentered}
